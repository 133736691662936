$custom-font-family: 'Syncopate Regular';

@media print {
  .layout-topbar,
  .layout-sidebar,
  .comment-container,
  .p-breadcrumb,
  .p-button-raised {
    display: none !important;
  }

  .print-container {
    padding: 1rem;
  }
}

.login-page {
  background-image: url('../public/images/cosmosis-b2-wham-7.png');
  background-size: cover;
}

body {
  position: relative;
  min-height: 100vh;
  margin: 0;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../public/images/cosmosis-b2-wham-7.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 0.5; /* Arka plan opaklığını düşür */
  z-index: -2; /* Ensure the background is behind the content */
}

body::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1); /* Yarı saydam beyaz katman */
  z-index: -1; /* Ensure the overlay is behind the content but in front of the background image */
}

.row-approved {
  background-color: var(--green-100) !important;
}

.list-demo {
  .product-name {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .product-description {
    margin: 0 0 1rem 0;
  }

  .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .product-category {
    font-weight: 600;
    vertical-align: middle;
  }

  .product-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;

    img {
      width: 150px;
      box-shadow:
        0 3px 6px rgba(0, 0, 0, 0.16),
        0 3px 6px rgba(0, 0, 0, 0.23);
      margin-right: 2rem;
    }

    .product-list-detail {
      flex: 1 1 0;
    }

    .p-rating {
      margin: 0 0 0.5rem 0;
    }

    .product-price {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      align-self: flex-end;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .p-button {
      margin-bottom: 0.5rem;
    }
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  .product-grid-item {
    margin: 0.5em;
    border: 1px solid #dee2e6;

    .product-grid-item-top,
    .product-grid-item-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    img {
      width: 75%;
      box-shadow:
        0 3px 6px rgba(0, 0, 0, 0.16),
        0 3px 6px rgba(0, 0, 0, 0.23);
      margin: 2rem 0;
    }

    .product-grid-item-content {
      text-align: center;
    }

    .product-price {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 576px) {
    .product-list-item {
      flex-direction: column;
      align-items: center;

      img {
        width: 75%;
        margin: 2rem 0;
      }

      .product-list-detail {
        text-align: center;
      }

      .product-price {
        align-self: center;
      }

      .product-list-action {
        display: flex;
        flex-direction: column;
      }

      .product-list-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
  }
}

.table-demo {
  .p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
      background-color: #607d8b;
    }
  }

  .p-datatable .p-column-filter {
    display: none;
  }

  .table-header {
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
  }

  .p-datatable.p-datatable-customers {
    .p-datatable-header {
      padding: 1rem;
      text-align: left;
      font-size: 1.5rem;
    }

    .p-paginator {
      padding: 1rem;
    }

    .p-datatable-thead > tr > th {
      text-align: left;
    }

    .p-datatable-tbody > tr > td {
      cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
    }
  }

  /* Responsive */
  .p-datatable-customers .p-datatable-tbody > tr > td > .p-column-title {
    display: none;
  }

  .customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-qualified {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-unqualified {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-negotiation {
      background: #feedaf;
      color: #8a5340;
    }

    &.status-new {
      background: #b3e5fc;
      color: #23547b;
    }

    &.status-renewal {
      background: #eccfff;
      color: #694382;
    }

    &.status-proposal {
      background: #ffd8b2;
      color: #805b36;
    }
  }

  .p-progressbar-value.ui-widget-header {
    background: #607d8b;
  }

  @media (max-width: 640px) {
    .p-progressbar {
      margin-top: 0.5rem;
    }
  }

  .product-image {
    width: 100px;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .orders-subtable {
    padding: 1rem;
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  .order-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.order-delivered {
      background: #c8e6c9;
      color: #256029;
    }

    &.order-cancelled {
      background: #ffcdd2;
      color: #c63737;
    }

    &.order-pending {
      background: #feedaf;
      color: #8a5340;
    }

    &.order-returned {
      background: #eccfff;
      color: #694382;
    }
  }

  @media screen and (max-width: 960px) {
    .p-datatable {
      &.p-datatable-customers {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }

        .p-datatable-tbody > tr {
          > td {
            text-align: left;
            display: block;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;

            .p-column-title {
              padding: 0.4rem;
              min-width: 30%;
              display: inline-block;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              font-weight: bold;
            }

            .p-progressbar {
              margin-top: 0.5rem;
            }
          }
        }

        colgroup {
          display: none;
        }
      }

      .p-datatable-header {
        .table-header {
          flex-direction: column !important;
        }
      }
    }
  }
}

.crud-demo {
  .p-toolbar {
    flex-wrap: wrap;
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  /* Responsive */
  .datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  @media screen and (max-width: 960px) {
    .p-datatable {
      &.datatable-responsive {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }

        .p-datatable-tbody > tr {
          border-bottom: 1px solid var(--surface-d);

          > td {
            text-align: left;
            display: flex;
            align-items: center;
            border: 0 none !important;
            width: 100% !important;
            float: left;
            clear: left;
            border: 0 none;

            .p-column-title {
              padding: 0.4rem;
              min-width: 30%;
              display: inline-block;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              font-weight: bold;
            }

            .p-progressbar {
              margin-top: 0.5rem;
            }

            .actions {
              display: flex;
              flex-grow: 1;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.docs {
  i:not([class~='pi']) {
    background-color: transparent;
    color: #2196f3;
    font-family: Monaco, courier, monospace;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    padding: 0 4px;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin: 0 2px;
    display: inline-flex;
  }

  a {
    font-weight: 500;
  }
}

pre[class*='language-'] {
  &:before,
  &:after {
    display: none !important;
  }

  code {
    border-left: 6px solid var(--surface-border) !important;
    box-shadow: none !important;
    background: var(--surface-ground) !important;
    margin: 1em 0;
    color: var(--text-color);
    font-size: 14px;
  }

  .token {
    &.tag,
    &.keyword {
      color: #2196f3 !important;
    }

    &.attr-name,
    &.attr-string {
      color: #2196f3 !important;
    }

    &.attr-value {
      color: #4caf50 !important;
    }

    &.punctuation {
      color: var(--text-color);
    }

    &.operator,
    &.variable,
    &.string {
      background: transparent;
    }
  }
}

.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.p-timeline-event-content,
.p-timeline-event-opposite {
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .customized-timeline {
    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;

      .p-timeline-event-content {
        text-align: left !important;
      }
    }

    .p-timeline-event-opposite {
      flex: 0;
    }

    .p-card {
      margin-top: 1rem;
    }
  }
}

.p-toast-bottom-right {
  z-index: 99999;
}

.fileUploadthreeDot > span > span {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100px !important;
}

.clickDoubleClickIgnore > button {
  position: absolute;
  width: 100% !important;
  opacity: 0 !important;
}

.clickDoubleClickIgnore > input {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.clickDoubleClickIgnore2 > button {
  opacity: 0 !important;
}

.card-container2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.card-item2 {
  flex: 1 1 calc(33.333% - 40px);
  box-sizing: border-box;
  display: flex;
}

.header-card {
  background: white; /* Beyaz arka plan */
  color: var(--primary-color-text);
  font-family: var(--custom-font-family);
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 3rem;
  border-radius: 15px;

  margin: 2rem auto;
  width: fit-content;
}

.header-card-text {
  background: linear-gradient(
    145deg,
    #01a0ad,
    #01a0ad,
    #01a0ad,
    #ec3025,
    #ec3025
  ); /* Watsons logosundaki renk geçişi */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard-custom-card {
  font-family: $custom-font-family, sans-serif;
  background: linear-gradient(145deg, #ffe259, #ffa751); /* Renk geçişi */
  border-radius: 30px;
  box-shadow:
    10px 10px 20px #c5c5c5,
    -10px -10px 20px #ffffff;
  padding: 20px;
  text-align: center;
  transition: transform 0.2s;
  color: white; /* Font rengi beyaz */
  font-weight: bold; /* Font kalınlığı bold */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: relative;

  &:hover {
    transform: translateY(-10px);
  }

  .dashboar-card-header {
    font-family: $custom-font-family, sans-serif;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 3rem;
    text-align: center;
    z-index: 1; 

    @media screen and (max-width: 960px) {
      font-size: 2rem;
    }
  }

  .dashboard-card-content {
    font-family: $custom-font-family, sans-serif;
    font-size: 20px;
    text-align: center;
    width: 70%;
    z-index: 1; 
  }

  .card-icon2 {
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 0.9; /* İkonu biraz şeffaf yapabiliriz */
    z-index: 0; /* İkonun z-index değerini düşürüyoruz */

    img {
      width: 100px; /* İkon boyutu */
      height: 100px;
    }
  }
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

.header-content {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.language-selector {
  position: absolute;
  top: 10px;
  right: 10px;
}

.danger-bg-color {
  background-color: #ff111175 !important;
}
.warning-bg-color {
  background-color: #f5d46ec7 !important;
}
.wrap-content {
  flex-wrap: wrap !important;
  word-wrap: break-word;
  word-break: break-all;
}