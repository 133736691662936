@font-face {
  font-family: "Syncopate Bold";
  src: url("../public/fonts/Syncopate-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-named-instance: "Bold";
}
@font-face {
  font-family: "Syncopate Regular";
  src: url("../public/fonts/Syncopate-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-named-instance: "Regular";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
