/* Utils */
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;
    border-radius: $borderRadius;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {

    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}
