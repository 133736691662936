.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;

    &::before {
        content: '';
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
    }
}

.public-form {
    border-radius: 56px;
    padding: 0.3rem;
    background: linear-gradient(180deg, rgb(240 178 108) 10%, rgba(33, 150, 243, 0) 30%);
}

.profile-btn {
    cursor: pointer;
}

.custom-datatable {
    .p-datatable-header {
        padding: 0.75rem 20px;
    }

    .p-datatable .p-datatable-tbody>tr>td:first-child,
    .p-datatable .p-datatable-tbody>tr>td:last-child {
        padding: 0.75rem 2.25rem;
    }

    .p-datatable .p-datatable-thead>tr>th:first-child,
    .p-datatable .p-datatable-thead>tr>th:last-child {
        padding: 0.75rem 2.25rem;
    }

    .p-datatable .p-datatable-thead>tr>th:not(:last-child) {
        border-right: 1px solid #dee2e6;
    }

    .p-treetable-table {
        table-layout: initial;
    }
}

.content-none {
    .p-panel-content {
        padding: 0;
    }
}

.p-float-label textarea~label {
    top: 50%;
    margin: 0;
    transform: translateY(-60%);
}

#languageMenu {
    .p-menuitem {
        padding: 5px
    }
}

.custom-card {
    width: 100%;
    background: #ffffff;
    margin-top: 5px;
    border: 1px solid var(--surface-border);
    box-shadow: rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;
    border-radius: 6px;
    min-height: 210px;
}

.card-header {
    padding: 20px;
}

.card-body {
    padding: 20px;
}

.p-float-label input:focus~label,
.p-float-label input:-webkit-autofill~label,
.p-float-label input.p-filled~label,
.p-float-label textarea:focus~label,
.p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label,
.p-float-label .p-tooltip-target-wrapper~label {
    top: 0.05rem;
    font-size: 12px;
    background: white;
    padding: 0 5px;
    color: rgba(76, 78, 100, 1);
}

.p-float-label label {
    color: rgba(76, 78, 100, 0.6);
}

.p-float-label .p-disabled+label,
.p-float-label label[disabled] {
    color: rgba(76, 78, 100, 0.4) !important;
}

.p-float-label input:focus,
.p-float-label .p-dropdown.p-inputwrapper-filled,
.p-float-label .p-calendar.p-inputwrapper-filled .p-inputtext,
.p-float-label input:-webkit-autofill,
.p-float-label input.p-filled,
.p-float-label textarea:focus,
.p-float-label textarea.p-filled {
    border: 1px solid rgba(76, 78, 100, 1);
    color: rgba(76, 78, 100, 1);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: rgba(76, 78, 100, 1) !important;
}

.custom-file-upload {
    .p-fileupload-content {
        border-radius: 0;
    }
}

.custom-panel {
    .p-panel-content {
        padding: 0px
    }

    .p-tabview-panels {
        padding: 0px
    }
}

.p-autocomplete {
    .p-autocomplete-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .p-autocomplete-dropdown {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}


.switch-lang-tab {
    .p-tabview-panels {
        border-bottom: 1px solid rgb(32 33 36 / 40%);
        border-left: 1px solid rgb(32 33 36 / 40%);
        border-right: 1px solid rgb(32 33 36 / 40%);
    }

    .p-tabview-nav-content {
        border-top: 1px solid rgb(32 33 36 / 40%);
        border-left: 1px solid rgb(32 33 36 / 40%);
        border-right: 1px solid rgb(32 33 36 / 40%);

        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
}

.search-panel {

    .p-panel-content,
    .p-panel-header {
        border: none;
    }
}

.accommodation-list {
    height: 100%;

    .p-listbox-list-wrapper {
        height: 100%;
    }
}

.custom-calendar {
    .p-inputtext {
        border-radius: 0;

    }
}

.more-button {
    color: #000 !important;
    background: #F0B26C !important;
    border: 1px solid #F0B26C !important;
    padding: 0.75rem 1.25rem !important;
    font-size: 1rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
    border-radius: 6px !important;

    &:hover {
        border-color: #000 !important;
    }
}

.custom-round-box {
    width: 40px;
    height: 40px;
    border: 2px solid#abc9fb;
    border-radius: 50%;
    top: 10px;
    right: 10px;
}

.outer-panel>.p-panel-content {
    padding: 0;
}

.outer-panel>.p-tabview-panels {
    padding: 0;
}


.custom-tree-table {
    .p-treetable-table {
        .p-treetable-toggler {
            float: left;
        }
    }
}

.p-inplace-content {
    height: 100%;
    display: flex;
    align-items: center;

    .p-inplace-content-close {
        height: 2rem;
        width: 2rem;
    }
}

.shape-wrapper{
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    .shape {
        height: 14px;
        width: 14px;
    }
}

.calendar-modal{
    .p-dialog-header-icon.p-dialog-header-close.p-link{
        color: white;
    }
}



.calendar-accordion {
    .p-accordion-content {
        padding: 0;
        margin-left: 8px;

        .calendar-accordion-child {
            .p-accordion-content {
                margin-left: 0;
            }
        }
    }

    .p-accordion {
        .p-accordion-tab {
            margin: 0;
        }
    }
}

.icon-button .pi {
    font-size: 19px;
  }

  .custom-panel {
    .p-panel-content {
        padding: 0px
    }

    .p-tabview-panels {
        padding: 0px
    }
 }

 .no-header-table .p-datatable-thead {
    display: none;
  }